/*
 * @Author: your name
 * @Date: 2021-09-27 16:02:21
 * @LastEditTime: 2021-11-12 11:36:35
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \灰名单\src\settings.js
 */
module.exports = {

  title: '路段车流统计分析系统',

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: true,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: false
}
