import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

// import Layout from "@/components/HelloWorld";

/**
 * Note: 路由配置项
 *
 * hidden: true                     // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true                 // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                  // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                  // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                  // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect             // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'               // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * query: '{"id": 1, "name": "ry"}' // 访问路由的默认传递参数
 * roles: ['admin', 'common']       // 访问路由的角色权限
 * permissions: ['a:a:a', 'b:b:b']  // 访问路由的菜单权限
 * meta : {
    noCache: true                   // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'                  // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'                // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false               // 如果设置为false，则不会在breadcrumb面包屑中显示
    activeMenu: '/system/user'      // 当路由设置了该属性，则会高亮相对应的侧边栏。
  }
 */

export const constantRoutes = [
  //   {
  //     path: "/redirect",
  //     component: Layout,
  //     hidden: true,
  //     children: [
  //       {
  //         path: "/redirect/:path(.*)",
  //         component: () => import("@/views/redirect"),
  //       },
  //     ],
  //   },
  //   {
  //     path: "/login",
  //     component: () => import("@/views/login"),
  //     hidden: true,
  //   },
  //   {
  //     path: "/register",
  //     component: () => import("@/views/register"),
  //     hidden: true,
  //   },
  //   {
  //     path: "/404",
  //     component: () => import("@/views/error/404"),
  //     hidden: true,
  //   },
  //   {
  //     path: "/401",
  //     component: () => import("@/views/error/401"),
  //     hidden: true,
  //   },
  //首页
  {
    path: "/",
    component: () => import("@/views/homePage/homePage"),
  },
  //人力资源
  {
    path: "/humanResources",
    component: () => import("@/views/humanResources/humanResources"),
  },
  //成长专区
  {
    path: "/wantToPublish",
    component: () => import("@/views/wantToPublish/wantToPublish"),
  },
  //好说话
  {
    path: "/easyToTalk",
    component: () => import("@/views/easyToTalk/easyToTalk"),
  },
  //好说话-帖子
  {
    path: "/easyToTalkPost",
    component: () => import("@/views/easyToTalk/easyToTalkPost"),
  },
  //我要发布
  {
    path: "/releaseTrends",
    component: () => import("@/views/releaseTrends/releaseTrends"),
  },
  //推荐
  {
    path: "/positionsRecommended",
    component: () =>
      import("@/views/positionsRecommended/positionsRecommended"),
  },
  //GPT
  {
    path: "/haojiwangGPT",
    component: () =>
      import("@/views/haojiwangGPT/haojiwangGPT"),
  },
  //岗位详细
  {
    path: "/jobDetails",
    component: () => import("@/views/jobDetails/jobDetails"),
  },
  //登录
  {
    path: "/login",
    component: () => import("@/views/login/loginAndRegister"),
  },
  //消息-聊天
  {
    path: "/messageChat",
    component: () => import("@/views/messageChat/messageChat"),
  },
  //公司详细
  {
    path: "/companyDetails",
    component: () => import("@/views/companyDetails/companyDetails"),
  },
  //在线简历
  {
    path: "/onlineResume",
    component: () => import("@/views/onlineResume/onlineResume"),
  },
  //企业端-认证
  {
    path: "/authenticationLogin",
    component: () => import("@/views/authentication/authenticationLogin"),
  },
  //企业端-认证2
  {
    path: "/authenticationLoginTwo",
    component: () => import("@/views/authenticationTwo/authenticationLoginTwo"),
  },
  //企业端-注册1
  {
    path: "/registerOne",
    component: () => import("@/views/register1/registerOne"),
  },
  //企业端-注册2
  {
    path: "/registerTwo",
    component: () => import("@/views/register2/registerTwo"),
  },
  //企业端-职位中心
  {
    path: "/positionCenter",
    component: () => import("@/views/positionCenter/positionCenter"),
  },
  //企业端-推荐人才
  {
    path: "/recommendedTalents",
    component: () => import("@/views/recommendedTalents/recommendedTalents"),
  },
  //企业端-搜索人才
  {
    path: "/searchForTalent",
    component: () => import("@/views/searchForTalent/searchForTalent"),
  },
  //企业端-会员服务
  {
    path: "/membershipService",
    component: () => import("@/views/membershipService/membershipService"),
  },
  //企业端-个人中心
  {
    path: "/personalCenter",
    component: () => import("@/views/personalCenter/personalCenter"),
  },
  //企业端-人才管理
  {
    path: "/talentManagement",
    component: () => import("@/views/talentManagement/talentManagement"),
  },
  //企业端-沟通
  {
    path: "/communicateLink",
    component: () => import("@/views/communicateLink/communicateLink"),
  },
  //企业中心
  {
    path: "/enterpriseCentre",
    component: () => import("@/views/enterpriseCentre/enterpriseCentre"),
  },
  //用户协议
  {
    path: "/termsOfService",
    component: () => import("@/views/components/TermsOfService"),
  }
];

// 防止连续点击多次路由报错
let routerPush = Router.prototype.push;
let routerReplace = Router.prototype.replace;
// push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((err) => err);
};
// replace
Router.prototype.replace = function push(location) {
  return routerReplace.call(this, location).catch((err) => err);
};

export default new Router({
  mode: "history", // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes,
});
