import request from "@/utils/request";

//城市数据
export function selectAddressCityAll(parma) {
  return request({
    url: `/company/sysDict/selectAddressCityAll`,
    method: "get",
    parma
  });
}

//默认地址
export function defaultAddressCity(parma) {
  return request({
    url: `/company/sysDict/defaultAddressCity?userId=`+parma,
    method: "get",
  });
}

//切换身份
export function switchIdentity(parma) {
  return request({
    url: `/webUser/webUser/switchIdentity`,
    method: "post",
    headers:{"content-type":"text/plain"},
    data:parma
  });
}

//热门职位加一
export function AddSelectCount(parma) {
  return request({
    url: `/company/sysjobclassification/AddSelectCount`,
    method: "post",
    headers:{"content-type":"text/plain"},
    data:parma
  });
}