<template>
  <div id="app">
    <!-- <router-view /> -->
    <HelloWorld
      :userInfo="userInfo"
      @update="getUserInfo"
      msg="Welcome to Your Vue.js App"
    />
  </div>
</template>

<script>
import HelloWorld from "./components/HelloWorld.vue";
import { getItem } from "@/utils/storage";
export default {
  data() {
    return {
      userInfo: {},
    };
  },
  name: "App",
  components: {
    HelloWorld,
  },
  created() {
    this.userInfo = getItem("userInfo");
  },
  methods: {
    getUserInfo() {
      this.userInfo = getItem("userInfo");
    },
  },
};
</script>

<style>
body {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
