/*
 * @Author: your name
 * @Date: 2021-09-27 16:02:21
 * @LastEditTime: 2021-12-16 14:47:37
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \灰名单\src\api\user.js
 */
import request from '@/utils/request'

// export function login(params) {
//   return request({
//     url: 'login',
//     method: 'get',
//     params
//   })
// }

export function getInfo(token) {
  return request({
    url: '/vue-admin-template/user/info',
    method: 'get',
    params: { token }
  })
}

export function logout() {
  return request({
    url: '/logout',
    method: 'get'
  })
}
//获取手机号验证码
export function getPhoneCode(phoneNum) {
  return request({
    url: `/webUser/sendVerificationCode?phoneNum=${phoneNum}`,
    method: 'post'
  })
}
//登录/注册
export function userLogin(phoneNum,code,type,password) {
  return request({
    url: `/webUser/webUserLogin`,
    method: 'post',
    data:{
      phoneNum:phoneNum,
      verificationCode:code,
      type:type,
      password:password
    }
  })
}
//获取用户信息
export function getUserInfo() {
  return request({
    url: `/webUser/webUser/info`,
    method: 'get'
  })
}

//查询用户信息
export function getUserInfo1(id) {
  return request({
    url: `/webUser/webUser/infoAndDetails?userIdd=`+id,
    method: 'get'
  })
}

//修改用户信息
export function editUserInfo(form) {
  return request({
    url: `/webUser/webUser/editUserInfo`,
    method: 'post',
    data:{
      ...form
    }
  })
}

//获取求职状态
export function getJobSearchStatus() {
  return request({
    url: `/webUser/userCurrentJobSearchStatus/getList`,
    method: 'get'
  })
}

//获取身份
export function getUserCurrentIdentity() {
  return request({
    url: `/webUser/userCurrentIdentity/getList`,
    method: 'get'
  })
}

//判断企业用户信息是否填写完整
export function isCompanyUserIntact(userId) {
  return request({
    url: `/webUser/webUser/isCompanyUserIntact/${userId}`,
    method: 'get'
  })
}

