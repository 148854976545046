<template>
  <div class="content">
    <el-row v-show="headSelected">
      <el-col :span="24">
        <div
          :class="
            navigationSelected == 5
              ? 'website-head2'
              : navigationSelected == 6
              ? 'website-head3'
              : 'website-head'
          "
        >
          <el-row>
            <el-col :span="24"
              ><div class="small-head">
                <el-row>
                  <el-col :span="8" :offset="4">
                    <div class="small-head-left">
                      <el-image
                        style="
                          width: 36px;
                          height: 30px;
                          margin-top: 10px;
                          margin-right: 10px;
                        "
                        :src="require('../assets/homepage/20240321160945.png')"
                      ></el-image>
                      <div class="website-name" @click="selectedNavigation(1)">
                        好冀网
                      </div>
                      <div class="el-icon-location website-positioning">
                        <!-- 石家庄 -->
                        {{
                          parentDefaultCity == null
                            ? "全部"
                            : parentDefaultCity.name
                        }}
                      </div>
                      <div class="switch-positioning" @click="showCity">
                        [切换]
                      </div>
                      <div
                        :class="
                          navigationSelected == 5
                            ? 'small-head-title2'
                            : 'small-head-title'
                        "
                        @click="toPositionsRecommended"
                      >
                        推荐
                      </div>
                      <!-- <div
                        class="small-head-title"
                        v-if="userInfo.identity == 0"
                      >
                        校园
                      </div> -->
                      <div
                        :class="
                          navigationSelected == 4
                            ? 'small-head-title2'
                            : 'small-head-title'
                        "
                        @click="toEasyToTalk"
                      >
                        好说话
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="8" style="position: relative">
                    <div class="small-head-right">
                      <div
                        :class="
                          userInfoSelected
                            ? 'click-user-info-yes'
                            : 'click-user-info'
                        "
                        @click="userInfoClick()"
                      >
                        <div class="user-info" v-if="!userInfo">默认用户</div>
                        <div class="user-info" v-if="userInfo">
                          {{ userInfo.username }}
                        </div>
                        <el-avatar
                          :size="28"
                          :src="userInfo.avatarUrl"
                          class="user-avatar"
                          v-if="userInfo"
                        ></el-avatar>
                        <el-avatar
                          :size="28"
                          :src="circleUrl"
                          class="user-avatar"
                          v-if="!userInfo"
                        ></el-avatar>
                      </div>
                      <div
                        class="user-info"
                        @click="toOnlineResume"
                        v-if="enterpriseRecruit == 0"
                      >
                        简历
                      </div>
                      <div
                        class="user-info"
                        @click="toMembershipService"
                        v-if="enterpriseRecruit == 1"
                      >
                        会员服务
                      </div>
                      <el-image
                        style="
                          width: 18px;
                          height: 17px;
                          margin-top: 18px;
                          margin-left: 8px;
                        "
                        :src="vipUrl"
                        v-if="enterpriseRecruit == 1"
                      ></el-image>
                      <div
                        class="user-info"
                        @click="toCommunicateLink"
                        v-if="enterpriseRecruit == 1"
                      >
                        我的收藏
                      </div>
                      <div
                        class="user-info"
                        @click="toCommunicateLink"
                        v-if="enterpriseRecruit == 1"
                      >
                        面试
                      </div>
                      <div class="user-message" @click="toMessageChat">
                        <el-badge is-dot>消息</el-badge>
                      </div>
                    </div>
                    <div
                      class="user-operate"
                      v-if="userInfoSelected"
                      @mouseleave="userInfoClickNo()"
                    >
                      <!-- <div class="upgradation-vip">
                        <div class="upgradation-vip-title">升级VIP</div>
                        <div class="upgradation-vip-operate">
                          <div class="upgradation-vip-operate-text">
                            快人一步
                          </div>
                          <div class="upgradation-vip-operate-text2">
                            去升级
                            <div class="el-icon-arrow-right"></div>
                          </div>
                        </div>
                      </div> -->
                      <div class="personal-center" @click="toPersonalCenter">
                        个人中心
                      </div>
                      <div
                        class="personal-center"
                        v-if="enterpriseRecruit == 1"
                        @click="toEnterpriseCenter"
                      >
                        企业中心
                      </div>
                      <div
                        class="personal-center-explanatory"
                        v-if="enterpriseRecruit == 0"
                      >
                        查看面试投递状态、编辑在线简历
                      </div>
                      <div class="account-security">
                        <div class="account-security-title">账号与安全中心</div>
                        <div class="account-security-explanatory">待优化</div>
                      </div>
                      <div class="privacy">隐私保护</div>
                      <div class="notify">消息通知</div>
                      <div
                        class="switching-position"
                        @click="
                          switchIdentity(
                            userInfo != null ? userInfo.identity : ''
                          )
                        "
                      >
                        <!-- 切换为招聘者 -->
                        {{
                          userInfo != null
                            ? userInfo.identity == 0
                              ? "切换为招聘者"
                              : "切换为求职者"
                            : ""
                        }}
                      </div>
                      <div class="log-out" @click="toLogin">退出登录</div>
                    </div>
                  </el-col>
                </el-row>
              </div></el-col
            >
          </el-row>
          <el-row v-show="sloganSelected">
            <el-col :span="6" :offset="3">
              <div class="website-slogan">上好冀网</div>
            </el-col>
          </el-row>
          <el-row v-show="sloganSelected">
            <el-col :span="6" :offset="4">
              <div class="website-slogan">不止是招聘</div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <el-row v-show="frameworkSelected">
      <el-col :span="24">
        <div class="navigation-bar">
          <el-row>
            <el-col :span="16" :offset="4">
              <el-row>
                <el-col :span="6">
                  <div
                    :class="
                      navigationSelected == 1
                        ? 'navigation-bar-title-selected'
                        : 'navigation-bar-title'
                    "
                    @click="selectedNavigation(1)"
                  >
                    首页
                  </div>
                </el-col>
                <el-col :span="6">
                  <div
                    :class="
                      navigationSelected == 2
                        ? 'navigation-bar-title-selected'
                        : 'navigation-bar-title'
                    "
                    @click="selectedNavigation(2)"
                  >
                    人才市场
                  </div>
                </el-col>
                <el-col :span="6">
                  <div
                    :class="
                      navigationSelected == 3
                        ? 'navigation-bar-title-selected'
                        : 'navigation-bar-title'
                    "
                    @click="selectedNavigation(3)"
                  >
                    成长专区
                  </div>
                </el-col>
                <el-col :span="6">
                  <div
                    :class="
                      navigationSelected == 4
                        ? 'navigation-bar-title-selected'
                        : 'navigation-bar-title'
                    "
                    @click="selectedNavigation(4)"
                  >
                    好说话
                  </div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <router-view
      v-if="!$route.meta.link"
      :key="key"
      @update="getUserInfo"
      :defaultCity="parentDefaultCity"
      :userInfo="userInfo"
    />
    <el-row v-show="bottomSelected">
      <el-col :span="24">
        <div class="foot-content">
          <div class="contact">
            <div class="contact-header">
              <div class="left">联系我们</div>
              <div class="right">CONTACT US</div>
            </div>
            <div class="contact-wrap">
              <div>公司地址：石家庄市长安区中集商务7层</div>
              <div>联系电话：031185659872</div>
              <div>
                <span style="color: rgba(0, 0, 0, 0)">联系电话</span
                >：15610848886
              </div>
              <div>邮箱账号：hebeihuajingrenli@163.com</div>
            </div>
          </div>
          <div class="site">
            <div class="site-left">
              <div class="title">关注公众号</div>
              <el-image
                style="width: 90px; height: 90px; margin-top: 10px"
                :src="
                  require('@/assets/homepage/a0d0185e41558d8613785f7749275c2_1.png')
                "
              ></el-image>
            </div>
          </div>
        </div>
        <div class="foot-bottom">
          <div>版权所有：河北蝉智科技软件有限责任公司</div>
          <div>
            ICP备案号：<a href="https://beian.miit.gov.cn"
              >冀ICP备2022004319号-7</a
            >
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 弹框 -->
    <City
      :showFlag="parentShowFlag"
      @changeFlag="changeFlag"
      @refreshAddress="parentRefreshAddress"
      :userInfo="userInfo"
    />
  </div>
</template>

<script>
import { defaultAddressCity, switchIdentity } from "@/api/helloWorld";
import City from "./city.vue";
import { getItem, setItem } from "@/utils/storage";
import { removeToken } from "@/utils/auth";
import { getUserInfo } from "@/api/user";
export default {
  data() {
    return {
      vipUrl: require("../assets/homepage/Group_110.png"),
      circleUrl: require("../assets/homepage/Group_716.png"),
      navigationSelected: 1, //导航栏选中的ID
      userInfoSelected: false, //用户操作显示与否
      frameworkSelected: true, //头部导航栏的显示与否
      bottomSelected: true, //底部框架显示与否
      headSelected: true, //顶部显示与否
      sloganSelected: true, //头部标语的显示与否
      enterpriseRecruit: 0, //求职端-0 企业端-1
      parentShowFlag: false,
      userInfo: null, //用户信息
      parentDefaultCity: null, //默认地址信息
    };
  },
  components: { City },
  computed: {
    key() {
      return this.$route.path;
    },
  },
  watch: {
    $route(to) {
      // to为新的路由对象
      console.log(to.fullPath); // 输出新的路径
      if (this.$route.path == "/") {
        this.navigationSelected = 1;
        this.frameworkSelected = true;
        this.bottomSelected = true;
        this.headSelected = true;
        this.sloganSelected = true;
      } else if (this.$route.path == "/humanResources") {
        this.navigationSelected = 2;
        this.frameworkSelected = true;
        this.sloganSelected = true;
        this.bottomSelected = false;
        this.headSelected = true;
      } else if (this.$route.path == "/wantToPublish") {
        this.navigationSelected = 3;
        this.frameworkSelected = true;
        this.sloganSelected = true;
        this.bottomSelected = false;
        this.headSelected = true;
      } else if (this.$route.path == "/easyToTalk") {
        this.navigationSelected = 4;
        this.frameworkSelected = true;
        this.sloganSelected = true;
        this.bottomSelected = false;
        this.headSelected = true;
      } else if (
        this.$route.path == "/releaseTrends" ||
        this.$route.path == "/easyToTalkPost"
      ) {
        this.navigationSelected = 4;
        this.frameworkSelected = true;
        this.sloganSelected = true;
        this.bottomSelected = false;
        this.headSelected = true;
      } else if (
        this.$route.path == "/positionsRecommended" ||
        this.$route.path == "/haojiwangGPT"
      ) {
        this.navigationSelected = 5;
        this.frameworkSelected = false;
        this.sloganSelected = false;
        this.bottomSelected = false;
        this.headSelected = true;
      } else if (this.$route.path == "/jobDetails") {
        this.navigationSelected = 6;
        this.frameworkSelected = false;
        this.sloganSelected = false;
        this.bottomSelected = false;
        this.headSelected = true;
      } else if (
        this.$route.path == "/login" ||
        this.$route.path == "/authenticationLogin" ||
        this.$route.path == "/authenticationLoginTwo" ||
        this.$route.path == "/registerOne" ||
        this.$route.path == "/registerTwo" ||
        this.$route.path == "/termsOfService"
      ) {
        this.navigationSelected = 7;
        this.frameworkSelected = false;
        this.sloganSelected = false;
        this.bottomSelected = false;
        this.headSelected = false;
      } else if (this.$route.path == "/messageChat") {
        this.navigationSelected = 6;
        this.frameworkSelected = false;
        this.sloganSelected = false;
        this.bottomSelected = false;
        this.headSelected = true;
      } else if (this.$route.path == "/companyDetails") {
        this.navigationSelected = 6;
        this.frameworkSelected = false;
        this.sloganSelected = false;
        this.bottomSelected = false;
        this.headSelected = true;
      } else if (this.$route.path == "/onlineResume") {
        this.navigationSelected = 6;
        this.frameworkSelected = false;
        this.sloganSelected = false;
        this.bottomSelected = false;
        this.headSelected = true;
      } else if (
        this.$route.path == "/positionCenter" ||
        this.$route.path == "/recommendedTalents" ||
        this.$route.path == "/searchForTalent" ||
        this.$route.path == "/membershipService" ||
        this.$route.path == "/personalCenter" ||
        this.$route.path == "/talentManagement" ||
        this.$route.path == "/communicateLink" ||
        this.$route.path == "/enterpriseCentre"
      ) {
        this.navigationSelected = null;
        this.frameworkSelected = false;
        this.sloganSelected = true;
        this.bottomSelected = false;
        this.headSelected = true;
      }
    },
    userInfo(newValue, oldValue) {
      this.userInfo = newValue;
      this.enterpriseRecruit = this.userInfo.identity;
      // this.getDefalutCity();
    },
  },
  mounted() {
    // if (this.userInfo) {
    //   this.enterpriseRecruit = this.userInfo.identity;
    //   this.parentIdentities = this.userInfo.identity;
    // }
  },
  created() {
    // this.getDefalutCity();
  },
  methods: {
    //获取默认城市地址
    getDefalutCity() {
      if (this.userInfo) {
        defaultAddressCity(this.userInfo.userId).then((res) => {
          this.parentDefaultCity = res.data.sysAddressCityEntity;
          setItem("defaultCity", { ...res.data.sysAddressCityEntity });
        });
      }
    },
    //修改showFlag
    changeFlag() {
      this.parentShowFlag = false;
    },
    //切换城市
    showCity() {
      this.parentShowFlag = true;
    },
    //刷新默认地址
    parentRefreshAddress(val) {
      // this.getDefalutCity();
      if (val == "") {
        this.parentDefaultCity = null;
        setItem("defaultCity", null);
      } else {
        this.parentDefaultCity = val;
        setItem("defaultCity", { ...val });
      }
    },
    //获取用户信息
    getUserInfo() {
      this.userInfo = getItem("userInfo");
    },
    //跳转登陆页面
    toLogin() {
      removeToken();
      this.$router.push("/login");
    },
    toCommunicateLink() {
      this.$router.push("/communicateLink");
    },
    userInfoClickNo() {
      this.userInfoSelected = false;
    },
    // 跳转个人中心
    toPersonalCenter() {
      this.userInfoSelected = false;
      if (this.userInfo.identity == 1) {
        this.$router.push("/personalCenter");
      } else {
        this.$router.push("/onlineResume");
      }
    },
    // 跳转会员服务
    toMembershipService() {
      this.$router.push("/membershipService");
    },
    //跳转好说话
    toEasyToTalk() {
      this.$router.push("/easyToTalk");
    },
    //跳转在线简历
    toOnlineResume() {
      this.$router.push("/onlineResume");
    },
    //跳转消息页面
    toMessageChat() {
      if (this.userInfo.identity == 1) {
        this.$router.push("/communicateLink");
      } else {
        this.navigationSelected = 6;
        this.$router.push("/messageChat");
      }
    },
    //跳转推荐页面
    toPositionsRecommended() {
      this.navigationSelected = 5;
      this.$router.push("/positionsRecommended");
    },
    //点击头像昵称显示操作
    userInfoClick() {
      if (this.userInfoSelected) {
        this.userInfoSelected = false;
      } else {
        this.userInfoSelected = true;
      }
    },
    //导航栏切换
    selectedNavigation(id) {
      this.navigationSelected = id;
      if (id == 1) {
        this.$router.push("/");
      } else if (id == 2) {
        this.$router.push("/humanResources");
      } else if (id == 3) {
        this.$router.push("/wantToPublish");
      } else if (id == 4) {
        this.$router.push("/easyToTalk");
      }
    },
    //切换身份
    switchIdentity(val) {
      if (val != "") {
        let data = {
          identity: val == 0 ? 1 : 0,
        };
        let n = val == "0" ? "1" : "0";
        switchIdentity(n).then((res) => {
          if (res.data.code == 0) {
            this.updateUserInfo();
            this.$router.push("/");
          }
        });
      }
    },
    //更新用户信息
    updateUserInfo() {
      getUserInfo().then((res) => {
        setItem("userInfo", { ...res.data.webUser });
        this.userInfo = res.data.webUser;
      });
    },
    //跳转企业中心
    toEnterpriseCenter() {
      this.$router.push("/enterpriseCentre");
    },
  },
};
</script>

<style lang="scss">
.content {
  width: 100%;
  background-color: #f4f4f6;
  .website-head {
    width: 100%;
    background: rgb(255, 87, 34);
  }
  .website-head2 {
    width: 100%;
    background: #ffffff;
  }
  .website-head3 {
    width: 100%;
    background: rgba(210, 127, 101, 1);
  }
  .small-head {
    width: 100%;
    height: 50px;
    background: rgba(0, 0, 0, 0.7);
    .small-head-left {
      height: 100%;
      display: flex;
      .website-name {
        color: rgba(255, 255, 255, 1);
        font-family: PingFang SC;
        font-weight: 600;
        font-size: 24px;
        line-height: 50px;
        margin-right: 20px;
        cursor: pointer;
      }
      .website-positioning {
        color: rgba(244, 81, 30, 1);
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 15px;
        line-height: 50px;
      }
      .switch-positioning {
        color: rgba(255, 255, 255, 1);
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 12px;
        line-height: 50px;
        margin-left: 10px;
        cursor: pointer;
      }
      .small-head-title {
        color: rgba(255, 255, 255, 1);
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 15px;
        line-height: 50px;
        margin-left: 20px;
        cursor: pointer;
      }
      .small-head-title2 {
        color: rgba(255, 87, 34, 1);
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 15px;
        line-height: 50px;
        margin-left: 20px;
        cursor: pointer;
      }
    }
    .small-head-right {
      height: 100%;
      display: flex;
      flex-direction: row-reverse;
      .click-user-info {
        display: flex;
      }
      .click-user-info-yes {
        display: flex;
        background: rgba(255, 202, 186, 0.3);
      }
      .user-avatar {
        margin-top: 11px;
        margin-left: 10px;
        cursor: pointer;
      }
      .user-info {
        color: rgba(255, 255, 255, 1);
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 15px;
        line-height: 50px;
        margin: 0 10px;
        cursor: pointer;
      }
      .user-message {
        width: 33px;
        height: 26px;
        margin: 0 10px;
        cursor: pointer;
        color: rgba(255, 255, 255, 1);
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 15px;
        margin-top: 13px;
      }
    }
    .user-operate {
      position: absolute;
      z-index: 3;
      background: #fff;
      right: 0;
      border-radius: 10px;
      padding: 12px 25px;
      width: 194px;
      box-shadow: -4px 3px 5.3px rgba(200, 48, 0, 0.25);
      // .upgradation-vip {
      //   background-image: url("../assets/homepage/Group_302.png");
      //   background-repeat: no-repeat;
      //   height: 57px;
      //   padding-top: 10px;
      //   .upgradation-vip-title {
      //     line-height: 22px;
      //     color: rgba(155, 69, 0, 1);
      //     font-family: PingFang SC;
      //     font-weight: bold;
      //     font-size: 16px;
      //     text-align: left;
      //     margin-left: 10px;
      //   }
      //   .upgradation-vip-operate {
      //     display: flex;
      //     justify-content: space-between;
      //     margin-top: 8px;
      //     .upgradation-vip-operate-text {
      //       margin-left: 10px;
      //       line-height: 17px;
      //       color: rgba(155, 69, 0, 1);
      //       font-family: PingFang SC;
      //       font-weight: 500;
      //       font-size: 12px;
      //     }
      //     .upgradation-vip-operate-text2 {
      //       line-height: 17px;
      //       color: rgba(155, 69, 0, 1);
      //       font-family: PingFang SC;
      //       font-weight: 500;
      //       font-size: 12px;
      //       margin-right: 10px;
      //     }
      //   }
      // }
      .personal-center {
        line-height: 20px;
        color: rgba(51, 51, 51, 1);
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 14px;
        margin-top: 10px;
        text-align: left;
        cursor: pointer;
      }
      .personal-center-explanatory {
        line-height: 17px;
        color: rgba(153, 153, 153, 1);
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 12px;
        margin-top: 4px;
        text-align: left;
      }
      .account-security {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        .account-security-title {
          line-height: 20px;
          color: rgba(51, 51, 51, 1);
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 14px;
        }
        .account-security-explanatory {
          background: rgba(255, 87, 34, 0.3);
          border-radius: 5px;
          padding: 0 4px;
          line-height: 17px;
          color: rgba(255, 61, 0, 1);
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 12px;
        }
      }
      .privacy {
        margin-top: 20px;
        line-height: 20px;
        color: rgba(51, 51, 51, 1);
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 14px;
        text-align: left;
      }
      .notify {
        margin-top: 20px;
        line-height: 20px;
        color: rgba(51, 51, 51, 1);
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 14px;
        text-align: left;
      }
      .switching-position {
        margin-top: 20px;
        line-height: 20px;
        color: rgba(51, 51, 51, 1);
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 14px;
        text-align: left;
        cursor: pointer;
      }
      .log-out {
        margin-top: 20px;
        line-height: 20px;
        color: rgba(51, 51, 51, 1);
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 14px;
        text-align: left;
        cursor: pointer;
      }
    }
  }
  .website-slogan {
    height: 50px;
    color: rgba(255, 255, 255, 1);
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 35px;
    line-height: 50px;
  }
  .navigation-bar {
    width: 100%;
    height: 50px;
    background: rgba(255, 255, 255, 1);
    .navigation-bar-title {
      height: 48px;
      line-height: 50px;
      color: rgba(51, 51, 51, 1);
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 16px;
      border-bottom: 2px solid #fff;
      cursor: pointer;
    }
    .navigation-bar-title-selected {
      height: 48px;
      line-height: 50px;
      color: rgb(255, 87, 34);
      font-family: PingFang SC;
      font-weight: 600;
      font-size: 16px;
      border-bottom: 2px solid rgb(255, 87, 34);
      border-radius: 2px;
      cursor: pointer;
    }
  }

  .foot-content {
    width: 100%;
    background: rgb(255, 87, 34);
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    .contact {
      width: 60%;
      font-size: 14px;
      margin-top: 1.5rem;
      margin-right: 1.2rem;
      color: #fff;
      .contact-header {
        display: flex;
        .left {
          width: 40%;
          text-align: left;
          border-bottom: 1px solid #fff;
        }
        .right {
          width: 60%;
          text-align: right;
          border-bottom: 1px solid #fff;
        }
      }
      .contact-wrap {
        text-align: left;
        div {
          margin-top: 0.3rem;
        }
      }
    }
    .site {
      width: 10%;
      font-size: 14px;
      margin-top: 20px;
      margin-left: 20px;
      color: #fff;
      display: flex;
      .site-left {
        width: 100%;
      }
    }
  }
  .foot-bottom {
    width: 100%;
    background-color: #585755;
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    justify-content: center;
    div {
      margin-left: 1.2rem;
      margin-right: 1.2rem;
      line-height: 2.05rem;
      a {
        text-decoration: none;
        color: #fff;
      }
    }
  }
}
</style>
