/*
 * @Author: your name
 * @Date: 2021-09-27 16:02:22
 * @LastEditTime: 2022-04-02 08:37:15
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \灰名单\src\utils\request.js
 */
import axios from "axios";
import { Message } from "element-ui";
import store from "@/store";
import { getToken } from "@/utils/auth";
import router from "@/router";

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent

    // if (store.getters.token) {
    config.headers["Token"] = getToken();
    // }
    return config;
  },
  (error) => {
    // do something with request error
    Message.error(error);
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    // 假设你的响应体中包含 code 字段
    const { data, status } = response;
    if (status === 200 && data && data.code !== 0 && data.code !== 200) {
      // 当 code 不等于 0 时，显示错误弹窗
      Message({
        message: data.msg || "未知错误",
        type: "error",
        duration: 5 * 1000,
      });
      if (data.code == 401) {
        router.push("/login");
      }
      // 拒绝这个 Promise
      // return Promise.reject(data);
    }
    // 如果一切正常，返回响应
    return response;
  },
  (error) => {
    // 这里处理网络错误或请求失败的情况
    console.log("err" + error); // for debug
    Message({
      message: error.message || "请求失败",
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

export default service;
