<template>
  <div class="cityDialog">
    <el-dialog
      :visible.sync="dialogVisible"
      width="700px"
      :before-close="handleClose"
      :modal="false"
    >
      <div class="close" @click="closeDialog">x</div>
      <div class="wrap">
        <div class="market">
          <el-button :class="num==-1?'selectedBtn':''" @click="switchCity('',-1)" style="margin-bottom:15px;">全部</el-button>
        </div>
        <div v-for="(item,index) in cityArr" :key="index">
          <div class="economize">{{item.name}}</div>
          <div class="market">
            <el-button :class="num==indexs?'selectedBtn':''" v-for="(items,indexs) in item.sysAddressCityEntitys" :key="indexs" @click="switchCity(items,indexs)" style="margin-bottom:15px;">{{items.name}}</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { selectAddressCityAll,updateDefaultAddressCity } from "@/api/city";
import { getItem } from '@/utils/storage';
import { getToken } from "@/utils/auth";
export default {
  props:{
    userInfo:{
      type:Object,
      default:null
    },
    showFlag:{
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
       dialogVisible: false ,
       cityArr:null, //城市数据
       thisPageUserInfo:null, //用户信息
       num:-1, //选中的btn
    };
  },
  created() {
    this.getCitys();
  },
  mounted() {},
  methods: {
    handleClose() {
      this.$emit('changeFlag',false);  
    },
    //获取地址数据
    //获取城市数据
    getCitys(){
      if (getToken()) {
        selectAddressCityAll().then(res=>{
          if(res.data){
            this.cityArr=res.data.sysAddressCityEntities;
          }
        })
      }
    },
    switchCity(val,n){
      // if(this.thisPageUserInfo!=null){

      // }else{
      //   this.thisPageUserInfo=getItem("userInfo");
      // }
      // updateDefaultAddressCity(this.thisPageUserInfo.userId,val.id).then(res=>{
      //   if(res.data.code==0){
      //     // this.$message.success("切换城市成功");
      //     
      //     this.$emit("refreshAddress");
      //   }
      // })
      this.num=n;
      this.handleClose();
      this.$emit("refreshAddress",val);
    },
    //关闭
    closeDialog(){
      this.handleClose();
    },
  },
  watch: {
    showFlag(newValue,oldValue){
        this.dialogVisible=newValue;
    },
    userInfo(newValue,oldValue){
      this.thisPageUserInfo=newValue;
      this.getCitys();
    }
  },
};
</script>

<style lang="scss">
.cityDialog{
  .close{
    width:15px;
    height:15px;
    border-radius: 100%;
    border:2px solid red;
    text-align: center;
    line-height: 15px;
    position:absolute;
    top:10px;
    right:10px;
    font-size:16px;
    color:red;
    cursor:pointer;
  }
  .el-dialog__header {
    display: none !important;
  }
  .wrap{
    .economize{
      text-align: left;
      padding:15px 0;
      font-size:16px;
    }
    .market{
      text-align: left;
      .el-button:hover {
        background: #ffd9cdff;
        color: #ff5722ff;
        border-color: #ff5722ff;
      }
      .selectedBtn {
        background: #ffd9cdff;
        color: #ff5722ff;
        border-color: #ff5722ff;
      }
    }
    .el-button+.el-button{
      margin-left:0;
    }
    .el-button{
      margin-right:10px;
    }
  }
}
</style>
