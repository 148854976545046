/*
 * @Author: your name
 * @Date: 2021-09-27 16:02:21
 * @LastEditTime: 2021-12-16 14:47:06
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \灰名单\src\store\modules\user.js
 */
import { login, logout } from "@/api/user";
import { getToken, setToken, removeToken } from "@/utils/auth";
import { resetRouter } from "@/router";
import { removeItem, setItem } from "@/utils/storage";
// import md5 from 'js-md5';
const getDefaultState = () => {
  return {
    token: getToken(),
    name: "",
    avatar: ""
  };
};
const state = getDefaultState();
const mutations = {
  RESET_STATE: state => {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  }
};
const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password } = userInfo;
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password})   //登录时转md5加密
        .then(response => {
          console.log("##")
          if (response.data.count > -1) {
            const { data } = response;
            setItem("userInfo", data.data);
            commit("SET_TOKEN", response.token);
            setToken(response.token);
          }
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // get user info
  // getInfo({ commit, state }) {
  //   return new Promise((resolve, reject) => {
  //     let userInfo = getItem("userInfo");
  //     if (userInfo) {
  //       resolve(userInfo);
  //     } else {
  //       reject();
  //     }
  //     // getInfo(state.token)
  //     //   .then(response => {
  //     //     const { data } = response.data;

  //     //     if (!data) {
  //     //       return reject("Verification failed, please Login again.");
  //     //     }
  //     //     const { displayname, role } = data;
  //     //     commit("SET_NAME", displayname);
  //     //     commit("SET_AVATAR", role);
  //     //     resolve(data);
  //     //   })
  //     //   .catch(error => {
  //     //     reject(error);
  //     //   });
  //   });
  // },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout(state.token)
        .then(() => {
          removeToken(); // must remove  token  first
          resetRouter();
          removeItem("userInfo");
          commit("RESET_STATE");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      removeToken(); // must remove  token  first
      commit("RESET_STATE");
      resolve();
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
