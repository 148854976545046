/*
 * @Author: YanHongtao
 * @Date: 2020-09-22 10:13:45
 * @LastEditors: YanHongtao
 * @LastEditTime: 2020-09-22 11:28:03
 * @Description: 本地存储方法
 * @FilePath: \单机房监测报警\src\utils\storage.js
 */

export const getItem = (name) => {
	const data = window.localStorage.getItem(name)
	try {
		return JSON.parse(data)
	} catch (error) {
		return data
	}
}

export const setItem = (name, value) => {
	const data = typeof value === 'object'
		? JSON.stringify(value) : value
	window.localStorage.setItem(name, data)
}

export const removeItem = (name) => {
	window.localStorage.removeItem(name)
}