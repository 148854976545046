import Vue from 'vue'
import App from './App.vue'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import router from './router'
import store from "./store";

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {/* 需要注册百度地图开发者来获取你的ak */ak: 'Wed4W6yy9vY4ZwfdDeG8oXwoE6aeYtDb'})
 
Vue.use(ElementUI)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
